import { useEffect, useState } from 'react';
import { SearchFontIcon } from 'react-md';
import { useDebounce } from '../../hooks';
import cx from 'classnames';
import styles from './SearchInput.module.scss';

const SearchInput = ({ searchTerm, setSearchTerm, placeholder, className }) => {
  const applyChange = useDebounce((nextValue) => handleChangeDebounced(nextValue, 'search'), 400);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearchTerm('');
  }, [setSearchTerm]);

  function handleChangeDebounced(val) {
    setSearchTerm(val);
  };

  const handleSearchChange = (value) => {
    setSearch(value);
    applyChange(value);
  };

  return (
    <div className={cx(styles.container, className)}>
      <input
        className={styles.input}
        type="text"
        onChange={(e) => handleSearchChange(e.target.value)}
        placeholder={placeholder}
        value={search}
      />
      <span className={styles.icon}>
        <SearchFontIcon
          color="rgba(0,0,0,.6)"
          width={24}
        />
      </span>
    </div>
  );
};

export default SearchInput;
