import { CardContent, FontIcon } from 'react-md';
import { format } from 'date-fns';
import TextTruncate from 'react-text-truncate';
import { getItem } from '../../utils/sessionStorage';
import { isNotEmpty } from '../../utils';
import americanFootballImg from './americanfootball.png';
import otherImg from './other.png';
import concertImg from './concert.png';
import cx from 'classnames';
import styles from './EventCard.module.scss';

const images = {
  'tag.event.sporting': americanFootballImg,
  'tag.event.other': otherImg,
  'tag.event.musical': concertImg
};

const EventCard = ({ event, selected, increaseCount, decreaseCount }) => {
  function getImage(tags) {
    const tag = isNotEmpty(tags) && tags.filter(t => {
      const nodes = t.path.split('.');

      return nodes.includes('event') ? true : false;
    });

    if (tag !== '') {
      return ((isNotEmpty(tag) && images[tag[0].path]) || otherImg);

    } else {
      return otherImg;
    }
  }

  function handleClick(e) {
    const selectedEvents = getItem('selectedEvents') || [];

    if (!selectedEvents.includes(e.id)) {
      increaseCount(e.id);

    } else {
      decreaseCount(e.id);
    }
  }

  return (
    <div
      onClick={() => handleClick(event)}
      className={cx(styles.container, { [styles.selected]: selected.includes(event.id) })}
    >
      <div className={cx(styles.imageContainer, styles.imageBackground)}>
        {selected.includes(event.id) ?
          <>
            <FontIcon className={cx(styles.checkButton, styles.background)}>brightness_1</FontIcon>
            <FontIcon className={cx(styles.checkButton, styles.blue)}>check_circle</FontIcon>
          </>
          :
          <FontIcon className={cx(styles.checkButton, styles.white)}>radio_button_unchecked</FontIcon>
        }
        <img src={getImage(event.metatags)} className="image" alt="event" />
      </div>
      <CardContent className={styles.text}>
        {event &&
          <div>
            <div className={styles.title}>
              <h5>
                <TextTruncate line={2} text={event.name} />
              </h5>
            </div>
            <div className={styles.details}>
              <div>
                <h5>
                  <FontIcon className={styles.green}>check_circle</FontIcon>
                  {event.vipCount} VIPs assigned
                </h5>
              </div>
              <div>
                <h5>
                  <FontIcon className={styles.blue}>room</FontIcon>
                  {event.landmark.name}
                </h5>
              </div>
              <div>
                <h5>
                  <FontIcon className={styles.blue}>event</FontIcon>
                  {format(new Date(event.parkingFrom), 'MM/dd/yy - hh:mm a')}
                </h5>
              </div>
            </div>
          </div>
        }
      </CardContent>
    </div>
  );
};


export default EventCard;
