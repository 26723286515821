import { Button, TextIconSpacing, FontIcon } from 'react-md';
import { SearchInput } from '../index';
import { setItem } from '../../utils/sessionStorage';
import styles from './TopActionBar.module.scss';

const TopActionBar = ({ events, selected, setView, listView, update, children, setSearchTerm, searchTerm }) => {
  const selectAll = () => {
    setItem('selectedEvents', events.map(event => event.id));
    update();
  };

  const deselectAll = () => {
    setItem('selectedEvents', []);
    update();
  };

  return (
    <div className="ab-container">
      <SearchInput
        className="ab-search"
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        placeholder="Search by keyword"
      />
      <div className="ab-middle">
        {children}
      </div>
      <div className="ab-actions">
        <Button
          id="card-view-toggle"
          className={styles.toggle}
          onClick={setView}
        >
          <TextIconSpacing
            icon={
              <FontIcon>{listView ? 'view_module' : 'view_list'}</FontIcon>
            }
          >
            {listView ? 'Card View' : 'List View'}
          </TextIconSpacing>
        </Button>
        {selected.length === events.length ?
          <Button className="button ghost" onClick={deselectAll}>Deselect All</Button> :
          <Button className="button ghost" onClick={selectAll}>Select All</Button>
        }
      </div>
    </div>
  );
};

export default TopActionBar;
